import "../scss/style.scss";

const spBtn = document.getElementById('sp-menu');
const navi = document.getElementById('navi');

spBtn.addEventListener('click', function () {
  spBtn.classList.toggle('open');
  navi.classList.toggle('open');
});

$(window).on('load resize', function () {
  var winW = $(window).width();
  var devW = 1240;
  if (winW <= devW) {
    $('html').removeClass('pc')
    $('html').addClass('no-pc')
  } else {
    $('html').removeClass('no-pc')
    $('html').addClass('pc')
  }
});

jQuery(function ($) {
  $('.g-navi .parent').on({
    'mouseenter': function () {
      if ($('html').hasClass('pc')) {
        $('.sub', this).addClass('open');
      }
    },
    'mouseleave': function () {
      if ($('html').hasClass('pc')) {
        $('.sub', this).removeClass('open');
      }
    },
    'click': function () {
      if ($('html').hasClass('no-pc')) {
        $('.sub', this).toggleClass('open');
      }
    }
  });

  // スクロール
  $('a[href^="#"]').click(function () {
    var speed = 500;
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      var s_h = 60;
    } else {
      var s_h = 84;
    }
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - s_h;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });

  // 開閉
  $(".open-close").click(function () {
    $(this).next('.detail-area').slideToggle();
    $(this).toggleClass('opened');
  });
  $(".close-btn").click(function () {
    $(this).parent('.detail-area').slideToggle();
    $(this).parents('.desc').children('.open-close').removeClass('opened');
  });


  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    $('footer .tel').wrapInner('<a href="tel:0666912227">');
    $('footer .mail').wrapInner('<a href="/contact.html">');
  }
});